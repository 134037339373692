// @flow

/**
 * Module dependencies.
 */

import { Box, Container, Grid } from 'components/core/layout';
import type { TemplateProps } from 'types/template';
import { getFluidImage } from 'utils/get-fluid-image';
import { graphql, useStaticQuery } from 'gatsby';
import { media, units } from '@seegno-labs/react-components/styles';
import FeaturesList from 'components/features-list';
import Header from 'components/core/header';
import Image from 'components/core/images/image';
import PageContainer from 'components/page-container';
import React, { type Node, useMemo } from 'react';
import TitleButtonSection from 'components/footer/title-button-section';
import styled from 'styled-components';
import useBreakpoint from 'hooks/use-breakpoint';

/**
 * About us page query.
 */

const aboutUsPageQuery = graphql`
  query {
    data: allSeegnoCmsAboutUsPage {
      nodes {
        content {
          header {
            backgroundImage {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
            image {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
            mobileImage {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
          }
          features {
            list {
              description
              id
              image {
                file {
                  publicURL
                }
              }
              title
            }
          }
        }
      }
    }
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  ${media.min('sm')`
    top: 50%;
    transform: translate(-50%, -50%);
  `}

  ${media.min('ms')`
    left: 40%;
    top: 60%;
    width: 140%;
  `}

  ${media.min('md')`
    left: -35%;
    transform: translateY(-50%);
    width: 140%;
  `}

  ${media.min('lg')`
    left: -25%;
    top: 10%;
    transform: none;
    width: 125%;
  `}

  ${media.min('xl')`
    left: -20%;
    width: 130%;
  `}
`;

/**
 * `AboutUs` template.
 */

const AboutUs = ({ pageContext }: TemplateProps): Node => {
  const about = useStaticQuery(aboutUsPageQuery);
  const data = about?.data?.nodes[0]?.content;
  const metatags = pageContext?.metatags;
  const content = pageContext?.content;
  const isMobile = useBreakpoint('ms', 'max');
  const features = useMemo(() => data?.features?.list.map(
    item => ({ ...item, image: item?.image?.file?.publicURL })
  ), [data.features.list]);

  return (
    <PageContainer
      footerElement={<TitleButtonSection {...content?.footerSection} />}
      metatags={metatags}
    >
      <Header
        backgroundFluidImage={getFluidImage(data?.header, 'backgroundImage')}
        buttonLabel={content?.header?.buttonLabel}
        buttonUrl={content?.header?.buttonUrl}
        description={content?.header?.description}
        isLargeImage
        title={content?.header?.title}
      >
        <ImageWrapper>
          <StyledImage fluid={getFluidImage(data?.header, isMobile ? 'mobileImage' : 'image')} />
        </ImageWrapper>
      </Header>

      <Container>
        <Grid>
          <Box
            gridColumn={'1 / -1'}
            gridColumnMd={'2 / -2'}
            marginBottom={units(12)}
            marginBottomMs={units(16)}
          >
            <FeaturesList items={features} />
          </Box>
        </Grid>
      </Container>
    </PageContainer>
  );
};

/**
 * Export `AboutUs` template
 */

export default AboutUs;
