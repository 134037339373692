// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import Button from 'components/core/button';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  buttonLabel: string,
  buttonUrl: string,
  title: string
|};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  color: ${color('primary')};
  padding: ${units(10)} 0;
  position: relative;
  text-align: center;

  ${media.min('md')`
    padding: ${units(14)} 0;
  `}

  ${media.min('xl')`
    padding: ${units(20)} 0;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  ${media.min('md')`
    max-width: 75%;
  `}
`;

/**
 * `TitleButtonSection` component.
 */

function TitleButtonSection(props: Props): Node {
  const { buttonLabel, buttonUrl, title } = props;

  return (
    <Section>
      <Container>
        <Content>
          <Type.H2
            marginBottom={units(8)}
            marginBottomLg={units(10)}
            textAlign={'center'}
          >
            {title}
          </Type.H2>

          <Button to={buttonUrl}>
            {buttonLabel}
          </Button>
        </Content>
      </Container>
    </Section>
  );
}

/**
 * Export `TitleButtonSection` component.
 */

export default TitleButtonSection;
